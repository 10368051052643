import {
    Box,
    Button, ButtonGroup,
    Divider,
    FormControlLabel,
    FormGroup,
    IconButton,
    Switch,
    TextField, Typography
} from "@mui/material";
import {useEffect, useRef, useState} from "react";
import {useInstances} from "../../services/instances";
import {useParams} from "react-router-dom";
import {Block, BlockModes, BlockTypes, Page} from "../../models/instance";
import {
    MenuButtonBold,
    MenuButtonItalic,
    MenuControlsContainer,
    MenuDivider,
    MenuSelectHeading,
    RichTextEditor, RichTextReadOnly
} from "mui-tiptap";
import {StarterKit} from "@tiptap/starter-kit";
import {Placeholder} from "@tiptap/extension-placeholder";
import {
    Add,
    AddCircle,
    Cancel,
    Delete,
    Edit,
    MoveDown,
    MoveUp,
    Remove,
    Save
} from "@mui/icons-material";

import "./instance-page-editor.scss"
import * as React from "react";
import {Photo} from "../../models/photo";
import {EditImageCard} from "../../components/Image-card";

export const PageEditorModes = {
    NORMAL: "normal",
    REORDER: "reorder"
}

export default function InstancePageEditor() {
    const {pageId} = useParams();

    const {selectedInstance, updatePage, uploadPageCoverPhoto} = useInstances();

    const [currentPage, setCurrentPage] = useState(null);
    const [hasChanges, setHasChanges] = useState(false);

    const [coverPhoto, setCoverPhoto] = useState(null);
    const [enabled, setEnabled] = useState(null);
    const [blocks, setBlocks] = useState(null);

    const [pageEditorMode, setPageEditorMode] = useState(PageEditorModes.NORMAL);

    useEffect(() => {
        console.log("[InstancePageEditor]: Mounting instance layout");

        return () => {
            console.log("[InstancePageEditor]: Unmounting instance layout");
        };
    }, []);

    useEffect(() => {
        if (selectedInstance) {
            const thisPage = selectedInstance.pages[pageId];
            if (thisPage) {
                console.log("[InstancePageEditor]: Setting current page, coverPhoto, enabled and blocks", {thisPage})
                setCurrentPage(thisPage);
                setCoverPhoto(new Photo(thisPage.coverPhoto));
                setEnabled(thisPage.enabled);
                setBlocks([...thisPage.blocks]);
            }
        }
    }, [selectedInstance, pageId]);

    const handleSetEditMode = (index) => {
        blocks[index].mode = BlockModes.EDIT;
        setBlocks(blocks.map(b => new Block(b)));
    }
    const onCancelEdit = (index) => {
        blocks[index] = new Block({
            ...currentPage.blocks[index],
            mode: BlockModes.READONLY
        });
        setBlocks(blocks.map(b => new Block(b)));
    }
    const onSaveEdit = (index) => {
        blocks[index].mode = BlockModes.READONLY;
        setBlocks(blocks.map(b => new Block(b)));
    }
    const handleToggleEnable = (e) => {
        setEnabled(e.target.checked);
    }
    const handleDeleteBlock = (index) => {
        blocks.splice(index, 1);
        setBlocks(blocks.map(b => new Block(b)));
    }
    const handleBlockChange = (index, block) => {
        blocks[index] = block;
        setBlocks(blocks.map(b => new Block(b)));
    }
    const handleMoveBlockUp = (index) => {
        if (index > 0) {
            [blocks[index - 1], blocks[index]] = [blocks[index], blocks[index - 1]];
        }
        setBlocks(blocks.map(b => new Block(b)));
    }
    const handleMoveBlockDown = (index) => {
        if (index !== -1 && index < blocks.length - 1) {
            [blocks[index], blocks[index + 1]] = [blocks[index + 1], blocks[index]];
        }
        setBlocks(blocks.map(b => new Block(b)));
    }
    const handleNewBlock = (blockType) => {
        setBlocks([
            ...blocks,
            new Block({type: blockType, mode: BlockModes.EDIT})
        ]);
    }

    const [originalBlockOrder, setOriginalBlockOrder] = useState(null);
    const handleEnableReorderMode = () => {
        setPageEditorMode(PageEditorModes.REORDER);
        setOriginalBlockOrder([...blocks]);
    }
    const handleCancelReorderMode = () => {
        setBlocks(originalBlockOrder);
        setOriginalBlockOrder(null);
        setPageEditorMode(PageEditorModes.NORMAL);
    }

    useEffect(() => {
        if (currentPage) {
            const updatedPage = new Page({
                ...currentPage,
                enabled,
                coverPhoto,
                blocks: blocks
            });
            setHasChanges(currentPage?.isDifferent(updatedPage));
        }
    }, [currentPage, enabled, coverPhoto, blocks]);

    const handleUpdatePage = async () => {
        await updatePage(pageId, new Page({
            ...currentPage,
            enabled,
            coverPhoto,
            blocks: blocks
        }));
        setOriginalBlockOrder(null);
        setPageEditorMode(PageEditorModes.NORMAL);
    }
    const handleUploadCoverPhoto = async (formData) => {
        await uploadPageCoverPhoto(pageId, formData);
    }

    console.log("[InstancePageEditor]: Rendering page editor")
    return <>
        {currentPage && <>
            <h1>{currentPage.title}</h1>
            <FormGroup sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <FormControlLabel sx={{flex: 1}} control={
                    <Switch checked={enabled || false} onChange={handleToggleEnable}/>
                } label="Enabled"/>
                <PageButtons pageEditorMode={pageEditorMode}
                             canReorder={!hasChanges && enabled && blocks.length > 1 && blocks.filter(b => b.mode === BlockModes.EDIT).length === 0}
                             canSave={hasChanges && blocks.filter(b => b.mode === BlockModes.EDIT).length === 0}
                             handleUpdatePage={handleUpdatePage} handleEnableReorderMode={handleEnableReorderMode}
                             handleCancelReorderMode={handleCancelReorderMode}
                />
            </FormGroup>
            {enabled && <Box>
                <h2>Cover photo</h2>
                <EditPageCoverPhoto key={coverPhoto?.name} coverPhoto={coverPhoto}
                                    onUploadCoverPhoto={handleUploadCoverPhoto}
                                    setCoverPhoto={setCoverPhoto}/>
                <h2>Blocks</h2>
                {blocks.map((block, idx) => <Box key={idx}>
                    <Box sx={{position: 'relative'}}>
                        <PageBlockEditor block={block} canMoveUp={idx > 0}
                                         onChange={(changedBlock) => handleBlockChange(idx, changedBlock)}
                        />
                        <BlockButtons sx={{position: 'absolute', bottom: '0', right: '0'}}
                                      blockMode={blocks[idx].mode}
                                      pageEditorMode={pageEditorMode}
                                      canMoveDown={idx < blocks.length - 1}
                                      canMoveUp={idx > 0}
                                      onSetEditMode={() => handleSetEditMode(idx)}
                                      onDelete={() => handleDeleteBlock(idx)}
                                      onChange={(changedBlock) => handleBlockChange(idx, changedBlock)}
                                      onMoveUp={() => handleMoveBlockUp(idx)}
                                      onMoveDown={() => handleMoveBlockDown(idx)}
                                      onCancel={() => onCancelEdit(idx)}
                                      onSaveChanges={() => onSaveEdit(idx)}
                        />
                    </Box>
                    <Divider sx={{margin: '30px 0'}}/>
                </Box>)}
                {blocks.length === 0 && <NoBlocksMessage/>}
                <AddBlockButtonsBox onAddBlock={handleNewBlock}/>
            </Box>}
        </>}
    </>
}

export function EditPageCoverPhoto({coverPhoto, setCoverPhoto, onUploadCoverPhoto}) {
    const handleFiles = async (files) => {
        const formData = new FormData();
        formData.append('cover-photo', files[0]);
        formData.append('width', coverPhoto.width);
        formData.append('height', coverPhoto.height);
        onUploadCoverPhoto(formData);
    }
    const handleIncreaseWidth = () => {
        setCoverPhoto(new Photo({
            ...coverPhoto,
            width: (coverPhoto.width || 450) + 15
        }));
    }
    const handleDecreaseWidth = () => {
        setCoverPhoto(new Photo({
            ...coverPhoto,
            width: (coverPhoto.width || 450) - 15
        }));
    }
    const handleIncreaseHeight = () => {
        setCoverPhoto(new Photo({
            ...coverPhoto,
            height: (coverPhoto.height || 150) + 15
        }));
    }
    const handleDecreaseHeight = () => {
        setCoverPhoto(new Photo({
            ...coverPhoto,
            height: (coverPhoto.height || 150) - 15
        }));
    }
    return <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }}>
        <Box sx={{position: 'relative'}}>
            <EditImageCard
                photo={coverPhoto}
                defaultWidth={450}
                defaultHeight={150}
                onFilesUploaded={handleFiles}/>

            {coverPhoto && <ButtonGroup sx={{
                flex: '0',
                margin: '15px 0',
                display: 'flex',
                flexDirection: 'column',
                position: 'absolute',
                right: '-50px',
                top: 'calc(50% - 55px)'
            }} variant="contained" color="primary">
                <IconButton color="primary" onClick={handleDecreaseHeight}><Remove/></IconButton>
                <IconButton color="primary" onClick={handleIncreaseHeight}><Add/></IconButton>
            </ButtonGroup>}
        </Box>
        {coverPhoto && <ButtonGroup sx={{
            flex: '0',
            margin: '15px 0'
        }} variant="contained" color="primary">
            <IconButton color="primary" onClick={handleDecreaseWidth}><Remove/></IconButton>
            <IconButton color="primary" onClick={handleIncreaseWidth}><Add/></IconButton>
        </ButtonGroup>
        }
    </Box>;
}

export function PageButtons({
                                pageEditorMode,
                                canReorder,
                                handleEnableReorderMode,
                                handleCancelReorderMode,
                                canSave,
                                handleUpdatePage
                            }) {
    return <ButtonGroup sx={{flex: '0', display: 'flex', flexDirection: 'row'}}>
        {pageEditorMode === PageEditorModes.NORMAL && <Button className="photo-save" variant="contained" color="info"
                                                              disabled={!canReorder}
                                                              onClick={handleEnableReorderMode}>
            Reorder
        </Button>}
        {pageEditorMode === PageEditorModes.REORDER && <Button className="photo-save" variant="contained" color="error"
                                                               onClick={handleCancelReorderMode}>
            Cancel
        </Button>}
        <Button className="photo-save" variant="contained" color="success" disabled={!canSave}
                onClick={handleUpdatePage}>
            Save
        </Button>
    </ButtonGroup>;
}

export function BlockButtons({
                                 blockMode,
                                 pageEditorMode,
                                 onMoveUp,
                                 canMoveUp,
                                 onMoveDown,
                                 canMoveDown,
                                 onDelete,
                                 onSetEditMode,
                                 onSaveChanges,
                                 onCancel,
                                 ...props
                             }) {
    return <ButtonGroup {...props}>
        {pageEditorMode === PageEditorModes.REORDER && <>
            <Button color="info" startIcon={<MoveUp/>} variant="outlined" onClick={onMoveUp}
                        disabled={!canMoveUp}>
                Move up
            </Button>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Button color="info" startIcon={<MoveDown/>} variant="outlined" onClick={onMoveDown} disabled={!canMoveDown}>
                Move down
            </Button>
        </>}
        {pageEditorMode !== PageEditorModes.REORDER && <>
            {blockMode === BlockModes.READONLY && <>
                <Button color="error" startIcon={<Delete />} variant="outlined" onClick={onDelete}>
                    Delete
                </Button>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Button color="info" startIcon={<Edit />} variant="outlined" onClick={onSetEditMode}>
                    Edit
                </Button>
            </>}
            {blockMode === BlockModes.EDIT && <>
                <Button color="error" startIcon={<Cancel />} variant="outlined" onClick={onCancel}>
                    Cancel
                </Button>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Button color="info" startIcon={<Save/>} variant="outlined" onClick={onSaveChanges}>
                    Save
                </Button>
            </>}
        </>}
    </ButtonGroup>;
}

export function NoBlocksMessage() {
    return <Box>
        No blocks have been added to this page, use the buttons below to adding some!
        <Divider sx={{margin: '30px 0'}}/>
    </Box>
}

export function AddBlockButtonsBox({onAddBlock}) {
    return <Box sx={{display: 'flex', padding: '25px', border: '1px dashed black', columnGap: '15px'}}>
        <AddBlockButton onClick={() => onAddBlock(BlockTypes.TITLE_AND_TEXT)}>
            <Typography sx={{fontSize: '14px', textTransform: 'none'}}>Block title</Typography>
            <Typography sx={{fontSize: '11px', textTransform: 'none'}}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </Typography>
        </AddBlockButton>
        <AddBlockButton onClick={() => onAddBlock(BlockTypes.TEXT)}>
            <Typography sx={{fontSize: '11px', textTransform: 'none'}}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </Typography>
        </AddBlockButton>
    </Box>
}

export function AddBlockButton({children, ...props}) {
    return <Button variant="outlined" className="btn-add-block" sx={{flex: 1, padding: '25px'}} {...props}>
        <Box sx={{width: "80%", textAlign: 'left', position: 'relative'}}>
            {children}
            <AddCircle className="add-icon" sx={{
                display: 'none',
                fontSize: '50px',
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                opacity: '0.5'
            }}/>
        </Box>
    </Button>;
}

export function PageBlockEditor({block, onChange}) {

    const handleBlockChange = (block) => {
        onChange(block);
    }
    return <Box sx={{border: '1px dashed #ccc', padding: '15px 15px 50px 15px'}}>
        {block.mode === BlockModes.EDIT && <>
            {block.type === BlockTypes.TEXT &&
                <TextBlockEditor block={block} onChange={handleBlockChange}/>}
            {block.type === BlockTypes.TITLE_AND_TEXT &&
                <TextAndTitleBlockEditor block={block} onChange={handleBlockChange}/>}
        </>}
        {block.mode === BlockModes.READONLY && <>
            {block.type === BlockTypes.TEXT &&
                <TextBlockReadOnly block={block} onChange={handleBlockChange}/>}
            {block.type === BlockTypes.TITLE_AND_TEXT &&
                <TextAndTitleBlockReadOnly block={block} onChange={handleBlockChange}/>}
        </>}
    </Box>;
}

function TextAndTitleBlockReadOnly({block}) {
    return <>
        <h1>{block.title}</h1>
        <RichTextReadOnly content={block.content} extensions={[StarterKit]}/>
    </>
}

function TextAndTitleBlockEditor({block, onChange}) {
    const handleTitleChange = (e) => {
        onChange(new Block({...block, title: e.target.value}));
    }
    const handleContentChange = (content) => {
        onChange(new Block({...block, content}));
    }

    return <FormGroup>
        <TextField variant="outlined" sx={{marginBottom: '15px'}} value={block.title || ''}
                   onChange={handleTitleChange} label="Title" placeholder="Title"/>
        <MyRichTextEditor content={block.content} onChange={handleContentChange}/>
    </FormGroup>;
}

function TextBlockReadOnly({block}) {
    return <RichTextReadOnly content={block.content} extensions={[StarterKit]}/>;
}

function TextBlockEditor({block, onChange}) {
    const handleContentChange = (content) => {
        onChange(new Block({...block, content}));
    }
    return <FormGroup>
        <MyRichTextEditor content={block.content} onChange={handleContentChange}/>
    </FormGroup>;
}

function MyRichTextEditor({content, onChange}) {
    const rteRef = useRef(null);

    const getContent = () => rteRef.current?.editor?.getHTML();
    // Listen for changes and call the onChange handler
    useEffect(() => {
        const editor = rteRef.current?.editor;

        if (editor && onChange) {
            // Subscribe to the editor's 'update' event
            const updateHandler = () => {
                const htmlContent = getContent();
                if (content !== htmlContent) {
                    onChange(htmlContent); // Trigger the onChange handler with updated content
                }
            };

            editor.on('update', updateHandler);

            // Clean up the event listener when the component is unmounted or updated
            return () => {
                editor.off('update', updateHandler);
            };
        }
    }, [onChange, rteRef, content]);

    return <RichTextEditor
        ref={rteRef}
        extensions={[StarterKit, Placeholder.configure({
            placeholder: "Add your own content here..."
        })]}
        content={content || ""}
        RichTextFieldProps={{
            variant: "outlined"
        }}
        renderControls={() => (
            <MenuControlsContainer>
                <MenuSelectHeading/>
                <MenuDivider/>
                <MenuButtonBold/>
                <MenuButtonItalic/>
                {/* Add more controls of your choosing here */}
            </MenuControlsContainer>
        )}
    />;
}