import {useAuth} from "./authentication/use-auth";
import {useCallback, useMemo} from "react";

export default function useAuthorizedApi() {
    const { userData, signOut } = useAuth();

    // Memoize `authHeader` since it depends on `userData`.
    const authHeader = useCallback((url) => {
        const token = userData?.access_token;
        const isLoggedIn = !!token;
        const isApiUrl = url.startsWith(window._env_.REACT_APP_API_URL);
        if (isLoggedIn && isApiUrl) {
            return { Authorization: `Bearer ${token}` };
        } else {
            return {};
        }
    }, [userData]);

    const handleResponse = useCallback(async (response) => {
        const text = await response.text();
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if ([401, 403].includes(response.status) && userData?.access_token) {
                localStorage.removeItem('user');
                await signOut();  // Handle sign out if unauthorized.
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    }, [userData, signOut]);

    // Memoize the `request` function so it doesn't get recreated on each render.
    const request = useCallback((method) => {
        /**
         * @param {string} url
         * @param {?any} [body]
         */
        return async (url, body) => {
            /** @type {RequestInit} **/
            const requestOptions = {
                method,
                headers: authHeader(url),
            };
            if (body) {
                if (body instanceof FormData) {
                    requestOptions.body = body;
                } else {
                    requestOptions.headers['Content-Type'] = 'application/json';
                    requestOptions.body = JSON.stringify(body);
                }
            }
            return handleResponse(await fetch(url, requestOptions));
        };
    }, [authHeader, handleResponse]);  // `userData` is used in `authHeader`, so it should be in dependencies

    // Memoize the API functions object to prevent re-creating them on every render.
    return useMemo(() => ({
        get: request('GET'),
        post: request('POST'),
        put: request('PUT'),
        patch: request('PATCH'),
        doDelete: request('DELETE')
    }), [request]);  // Only depend on the memoized `request` function.
}