import {AuthProvider} from "./auth-context";

function keycloak({children}) {
    return <AuthProvider
        authority="https://keycloak.novox.be/realms/Novox"
        autoSignIn={false}
        scope="openid roles"
        loadUserInfo={true}
        clientId="photos"
        silentRedirectUri={`${window.location.protocol}//${window.location.host}/signin-keycloak`}
        redirectUri={`${window.location.protocol}//${window.location.host}/signin-keycloak`}
        postLogoutRedirectUri={`${window.location.protocol}//${window.location.host}/signout-keycloak`}
    >
        {children}
    </AuthProvider>;
}
export default keycloak;