import {useState} from "react";
import {Instance} from "../models/instance";
import {
    Box,
    Button,
    Card,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup
} from "@mui/material";
import * as React from "react";
import {useInstances} from "../services/instances";

export default function InstanceLayoutEditor({instance}) {
    const {updateInstance} = useInstances();

    const homeLayouts = [{
        id: "layout-1",
        name: "Left - Right"
    }, {
        id: "layout-2",
        name: "Right - Left"
    }, {
        id: "layout-3",
        name: "Middle"
    }];
    const [selectedHomeLayout, setSelectedHomeLayout] = useState(instance.homeLayout || 1);
    const handleSelectLayout = (event) => {
        setSelectedHomeLayout(event.target.value);
    }
    const instanceHasChanges = () => {
        return instance.homeLayout !== selectedHomeLayout;
    }
    const handleSaveInstance = async () => {
        if (instanceHasChanges()) {
            await updateInstance(new Instance({...instance, homeLayout: selectedHomeLayout}));
        }
    }
    return <>
        <Card sx={{padding: '15px'}}>
            <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">Home</FormLabel>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={selectedHomeLayout}
                    onChange={handleSelectLayout}
                    name="radio-buttons-group"
                >
                    {homeLayouts.map(layout =>
                        <FormControlLabel key={layout.id} value={layout.id} control={<Radio/>} label={layout.name}/>
                    )}
                </RadioGroup>
            </FormControl>
            <Box className="input-buttons">
                <Box className="left-buttons">
                    <Button className="photo-save" variant="contained" color="info"
                            disabled={!instanceHasChanges()}
                            onClick={handleSaveInstance}>
                        Save
                    </Button>
                </Box>
            </Box>
        </Card>
    </>
}