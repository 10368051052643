import * as React from "react";
import {useEffect, useState} from "react";
import {
    AddCircle,
    KeyboardArrowRight,
    MoveDown,
    MoveUp
} from "@mui/icons-material";
import {Link, useNavigate} from "react-router-dom";
import Loader from "../../components/Loader";

import {
    Box,
    Button, ButtonGroup,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Divider, IconButton,
    TextField
} from "@mui/material";
import {Album} from "../../models/album";

import "./instance-albums-page.scss"
import {useAlbums} from "../../services/albums";
import {useInstances} from "../../services/instances";
import {ImageCard} from "../../components/Image-card";

function InstanceAlbumsPage() {
    const navigate = useNavigate();
    const {selectedInstance} = useInstances();
    const {
        isLoading,
        albums,
        createAlbum,
        patchAlbum,
        deleteAlbum,
        moveAlbumUp,
        moveAlbumDown,
        albumsApiError
    } = useAlbums();

    const [updatedAlbums, setUpdatedAlbums] = useState(null);
    const [newAlbumModalOpen, setNewAlbumModalOpen] = React.useState(false);

    const handleAlbumNameChange = (albumIndex, e) => {
        updatedAlbums[albumIndex].name = e.target.value;
        setUpdatedAlbums([...updatedAlbums]);
    }
    const handleAlbumDescriptionChange = (albumIndex, e) => {
        updatedAlbums[albumIndex].description = e.target.value;
        setUpdatedAlbums([...updatedAlbums]);
    }
    const albumHasChanges = (idx) => {
        const albumId = updatedAlbums[idx].albumId;
        console.log(`Checking if album with id ${albumId} has changes`, albums)
        return updatedAlbums[idx].name !== albums[selectedInstance.instanceId][albumId].name
            || updatedAlbums[idx].description !== albums[selectedInstance.instanceId][albumId].description;
    }

    useEffect(() => {
        if (!updatedAlbums && selectedInstance && albums && albums[selectedInstance.instanceId]) {
            console.log("Setting updated albums")
            setUpdatedAlbums(Object.values(albums[selectedInstance.instanceId]).map(album => new Album(album)));
        }
    }, [updatedAlbums, setUpdatedAlbums, albums, selectedInstance]);

    const handleSaveAlbum = async (idx) => {
        if (albumHasChanges(idx)) {
            await patchAlbum(updatedAlbums[idx]);
        }
    }

    const handleDeleteAlbum = async (e, albumId) => {
        await deleteAlbum(albumId);
        setUpdatedAlbums(updatedAlbums.filter(a => a.albumId !== albumId).map(album => new Album(album)));
    }

    const handleMoveAlbumDown = async (idx) => {
        await moveAlbumDown(updatedAlbums[idx].albumId);
        const origAlbum = updatedAlbums[idx];
        updatedAlbums[idx] = updatedAlbums[idx + 1];
        updatedAlbums[idx + 1] = origAlbum;
        setUpdatedAlbums([...updatedAlbums]);
    }

    const handleMoveAlbumUp = async (idx) => {
        await moveAlbumUp(updatedAlbums[idx].albumId);
        const origAlbum = updatedAlbums[idx];
        updatedAlbums[idx] = updatedAlbums[idx - 1];
        updatedAlbums[idx - 1] = origAlbum;
        setUpdatedAlbums([...updatedAlbums]);
    }
    const handleNewAlbum = () => {
        setNewAlbumModalOpen(true);
    }
    const handleCloseNewAlbum = () => {
        setNewAlbumModalOpen(false);
    };
    return <Box id="portfolio-managers">
        <Box id="page-title">
            <Box id="left-part">
                <Box className="title">Albums</Box>
                {updatedAlbums &&
                    <Box sx={{marginTop: '13px'}} className="photos-count">({updatedAlbums.length} albums)</Box>}
            </Box>
            <Box id="right-part">
                <Button id="page-save" variant="contained" color="success" startIcon={<AddCircle/>}
                        onClick={handleNewAlbum}>
                    New album
                </Button>
            </Box>
        </Box>
        {albumsApiError && <Box className="error-box">{albumsApiError}</Box>}
        {!albumsApiError && updatedAlbums?.length > 0 && <Box>
            {updatedAlbums.map((album, idx) => <>
                    <Box sx={{padding: '15px', display: 'flex'}} key={album.albumId}>
                        <ImageCard sx={{marginRight: '35px'}} photo={album.coverPhoto}
                                   onClick={() => navigate(`${album.albumId}`)}/>
                        <Box sx={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                            <Box>
                                <Box sx={{display: 'flex', marginBottom: '15px', columnGap: '25px'}}>
                                    <TextField fullWidth={true} variant="standard"
                                               value={album.name} onChange={(e) => handleAlbumNameChange(idx, e)}
                                    />
                                    <Button endIcon={<KeyboardArrowRight/>} variant="contained"
                                            color="info"
                                            onClick={() => navigate(`${album.albumId}`, {state: {album: updatedAlbums[idx]}})}>
                                        Photos
                                    </Button>
                                </Box>
                                <Box className="description input-element">
                                    <TextField multiline={true} fullWidth={true} rows={2}
                                               onChange={(e) => handleAlbumDescriptionChange(idx, e)}
                                               value={album.description || ""}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                <ButtonGroup>
                                    <Button variant="contained" color="error"
                                            onClick={(e) => handleDeleteAlbum(e, album.albumId)}>
                                        Delete
                                    </Button>
                                    <Button variant="contained" color="info"
                                            disabled={!albumHasChanges(idx)}
                                            onClick={() => handleSaveAlbum(idx)}>
                                        Save
                                    </Button>
                                </ButtonGroup>
                                <ButtonGroup variant="contained">
                                    <IconButton
                                        style={{visibility: (idx > 0 ? "visible" : "hidden")}}
                                        variant="contained"
                                        onClick={() => handleMoveAlbumUp(idx)}>
                                        <MoveUp color="info"/>
                                    </IconButton>
                                    <Divider orientation="vertical" variant="middle" flexItem />
                                    <IconButton
                                        style={{visibility: (idx < updatedAlbums.length - 1 ? "visible" : "hidden")}}
                                        variant="contained"
                                        onClick={() => handleMoveAlbumDown(idx)}>
                                        <MoveDown color="info"/>
                                    </IconButton>
                                </ButtonGroup>
                            </Box>
                        </Box>
                    </Box>
                    <Divider sx={{margin: '25px 0'}} variant="fullWidth"/>
                </>
            )}
        </Box>}
        {!albumsApiError && updatedAlbums?.length === 0 &&
            <Box><Link to="new" state={{album: new Album()}}>Click here</Link> to create your first album!</Box>}
        <Loader loading={isLoading}/>
        <Dialog
            open={newAlbumModalOpen}
            onClose={handleCloseNewAlbum}
            PaperProps={{
                component: 'form',
                onSubmit: async (event) => {
                    event.preventDefault();
                    const formData = new FormData(event.currentTarget);
                    const formJson = Object.fromEntries(formData.entries());
                    const name = formJson.name;
                    const album = await createAlbum(name);
                    handleCloseNewAlbum();
                    if (album) {
                        navigate(`/${selectedInstance.instanceId}/albums/${album.albumId}`);
                    }
                },
            }}
        >
            <DialogTitle>Create album</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Choose a album name
                </DialogContentText>
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="name"
                    name="name"
                    label="Name"
                    type="text"
                    fullWidth
                    variant="standard"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseNewAlbum}>Cancel</Button>
                <Button type="submit">Create</Button>
            </DialogActions>
        </Dialog>
    </Box>
}

export default InstanceAlbumsPage;