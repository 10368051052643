import useAuthorizedApi from "../authorized-api";
import {Album} from "../../models/album";
import {useCallback, useMemo, useState} from "react";

const apiUrl = window._env_.REACT_APP_API_URL;
/**
 * @typedef {Object} UseAlbumsApi
 * @property {function(instanceId: string): Promise<Album[]>} fetchAlbums - Fetches all albums for a given instance.
 * @property {function(instanceId: string, name: string): Promise<Album>} createAlbum - Creates a new album for a given instance.
 * @property {function(instanceId: string, album: Album): Promise<Album>} patchAlbum - Updates an existing album for a given instance.
 * @property {function(instanceId: string, albumId: string): Promise<Album[]>} deleteAlbum - Deletes an album by ID for a given instance.
 * @property {function(instanceId: string, albumId: string): Promise<Album[]>} moveAlbumDown - Moves an album down in the order for a given instance.
 * @property {function(instanceId: string, albumId: string): Promise<Album[]>} moveAlbumUp - Moves an album up in the order for a given instance.
 * @property {function(instanceId: string, albumId: string, photo: Photo): Promise<Album>} patchPhoto - Updates a photo in an album for a given instance.
 * @property {function(instanceId: string, albumId: string, formData: FormData): Promise<Album>} uploadPhotos - Uploads photos to an album for a given instance.
 * @property {function(instanceId: string, albumId: string, photoId: string): Promise<Album>} deletePhoto - Deletes a photo by ID from an album for a given instance.
 * @property {function(instanceId: string, albumId: string, photoId: string): Promise<Album>} movePhotoDown - Moves a photo down in the order within an album for a given instance.
 * @property {function(instanceId: string, albumId: string, photoId: string): Promise<Album>} movePhotoUp - Moves a photo up in the order within an album for a given instance.
 */

/**
 * Custom hook for managing albums API calls.
 * @returns {UseAlbumsApi}
 */
export default function useAlbumsApi() {
    const {get, post, patch, doDelete} = useAuthorizedApi();
    const [isLoading, setIsLoading] = useState(false);

    const tryApiCall = useCallback(async (func) => {
        try {
            setIsLoading(true);
            return await func();
        } catch (err) {
            console.error("An error occured in the Albums API", err);
            throw err;
        } finally {
            setIsLoading(false);
        }
    }, []);

    const fetchAlbums = useCallback((instanceId) => {
        return tryApiCall(async () => {
            const response = await get(`${apiUrl}/api/instances/${instanceId}/albums`);
            return response.data.map(album => new Album(album));
        });
    }, [get, tryApiCall]);

    const patchAlbum = useCallback((instanceId, album) => {
        const patches = [];
        if (album.name) patches.push({op: "replace", path: "/name", value: album.name});
        if (album.description) patches.push({op: "replace", path: "/description", value: album.description});
        if (album.sort) patches.push({op: "replace", path: "/sort", value: album.sort});

        if (patches.length === 0) return null;

        return tryApiCall(async () => {
            const response = await patch(`${apiUrl}/api/instances/${instanceId}/albums/${album.albumId}`, patches);
            return new Album(response.data);
        });
    }, [patch, tryApiCall]);

    const createAlbum = useCallback((instanceId, name) => {
        return tryApiCall(async () => {
            console.log("Creating album for instance with name", instanceId, name);
            const response = await post(`${apiUrl}/api/instances/${instanceId}/albums`, {
                name
            });
            return new Album(response.data);
        });
    }, [post, tryApiCall]);

    const deleteAlbum = useCallback((instanceId, albumId) => {
        return tryApiCall(async () => {
            await doDelete(`${apiUrl}/api/instances/${instanceId}/albums/${albumId}`);
        });
    }, [doDelete, tryApiCall]);

    const moveAlbumDown = useCallback((instanceId, albumId) => {
        return tryApiCall(async () => {
            const response = await post(`${apiUrl}/api/instances/${instanceId}/albums/${albumId}/move-down`);
            return response.data.map(a => new Album(a));
        });
    }, [post, tryApiCall]);

    const moveAlbumUp = useCallback((instanceId, albumId) => {
        return tryApiCall(async () => {
            const response = await post(`${apiUrl}/api/instances/${instanceId}/albums/${albumId}/move-up`);
            return response.data.map(a => new Album(a));
        });
    }, [post, tryApiCall]);

    const patchPhoto = useCallback((instanceId, albumId, photo) => {
        const patches = [];
        patches.push({op: "replace", path: "/name", value: photo.name});
        patches.push({op: "replace", path: "/description", value: photo.description});

        if (patches.length === 0) return null;

        return tryApiCall(async () => {
            const response = await patch(`${apiUrl}/api/instances/${instanceId}/albums/${albumId}/photos/${photo.photoId}`, patches);
            return new Album(response.data);
        });
    }, [patch, tryApiCall]);

    const uploadPhotos = useCallback(async (instanceId, albumId, formData) => {
        return tryApiCall(async () => {
            const response = await post(`${apiUrl}/api/instances/${instanceId}/albums/${albumId}/upload-photos`, formData);
            return new Album(response.data);
        });
    }, [post, tryApiCall]);

    const deletePhoto = useCallback(async (instanceId, albumId, photoId) => {
        return tryApiCall(async () => {
            const response = await doDelete(`${apiUrl}/api/instances/${instanceId}/albums/${albumId}/photos/${photoId}`);
            return new Album(response.data);
        });
    }, [doDelete, tryApiCall]);

    const movePhotoDown = useCallback(async (instanceId, albumId, photoId) => {
        return tryApiCall(async () => {
            const response = await post(`${apiUrl}/api/instances/${instanceId}/albums/${albumId}/photos/${photoId}/move-down`);
            return new Album(response.data);
        });
    }, [post, tryApiCall]);

    const movePhotoUp = useCallback(async (instanceId, albumId, photoId) => {
        return tryApiCall(async () => {
            const response = await post(`${apiUrl}/api/instances/${instanceId}/albums/${albumId}/photos/${photoId}/move-up`);
            return new Album(response.data);
        });
    }, [post, tryApiCall]);

    return useMemo(() => ({
        isLoading,
        fetchAlbums,
        createAlbum,
        patchAlbum,
        deleteAlbum,
        moveAlbumDown,
        moveAlbumUp,
        patchPhoto,
        uploadPhotos,
        deletePhoto,
        movePhotoDown,
        movePhotoUp,
    }), [
        isLoading,
        fetchAlbums,
        createAlbum,
        patchAlbum,
        deleteAlbum,
        moveAlbumDown,
        moveAlbumUp,
        patchPhoto,
        uploadPhotos,
        deletePhoto,
        movePhotoDown,
        movePhotoUp
    ]);
}