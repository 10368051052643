import {AddCircle, RemoveCircle} from "@mui/icons-material";
import * as React from "react";
import {useEffect, useState} from "react";
import {useInstances} from "../services/instances";
import {Button, Card, IconButton, InputAdornment, TextField} from "@mui/material";
import {NavLink} from "react-router-dom";
import {Instance} from "../models/instance";
import {EditImageCard} from "./Image-card";

/**
 * @param {Instance} instance
 * @param props
 * @return {Element}
 * @constructor
 */
export default function InstanceCardEditor({instance}) {
    const {
        deleteInstance,
        updateInstance,
        uploadCoverPhoto
    } = useInstances();

    const [newInstanceUrl, setNewInstanceUrl] = useState("");
    const handleFiles = async (files) => {
        const formData = new FormData();
        formData.append('cover-photo', files[0]);
        await uploadCoverPhoto(instance.instanceId, formData);
    }

    const [instanceToUpdate, setInstanceToUpdate] = useState(null);
    useEffect(() => {
        setInstanceToUpdate(new Instance(instance))
    }, [instance])
    useEffect(() => {
        if (instance.instanceId !== instanceToUpdate?.instanceId) {
            setInstanceToUpdate(new Instance(instance));
        }
    }, [instance, instanceToUpdate]);
    const handleInstanceTitleChange = (e) => {
        setInstanceToUpdate(new Instance({
            ...instanceToUpdate,
            title: e.target.value
        }));
    }
    const handleInstanceSubtitleChange = (e) => {
        setInstanceToUpdate(new Instance({
            ...instance,
            subtitle: e.target.value
        }));
    }
    const handleNewInstanceUrlChange = (e) => {
        setNewInstanceUrl(e.target.value);
    }
    const handleAddNewInstanceUrl = () => {
        setInstanceToUpdate(new Instance({
            ...instanceToUpdate,
            urls: [...instanceToUpdate.urls, newInstanceUrl]
        }));
        setNewInstanceUrl("");
    }
    const handleDeleteUrl = (e, url) => {
        setInstanceToUpdate(new Instance({
            ...instanceToUpdate,
            urls: instanceToUpdate.urls.filter(u => u !== url)
        }));
    }

    const instanceHasChanges = () => {
        return (instanceToUpdate.title || "") !== (instance.title || "") ||
            (instanceToUpdate.subtitle || "") !== (instance.subtitle || "") ||
            JSON.stringify(instanceToUpdate.urls) !== JSON.stringify(instance.urls);
    }
    const handleSaveInstance = async () => {
        if (instanceHasChanges()) {
            await updateInstance(instanceToUpdate);
        }
    }
    const handleDeleteInstance = async () => {
        await deleteInstance(instance.instanceId);
    }

    return <Card className="card-layout photo" sx={{padding: '15px'}}>
        {instanceToUpdate && <>
            <EditImageCard sx={{marginRight: "35px"}} photo={instanceToUpdate.coverPhoto}
                           onFilesUploaded={handleFiles}/>
            <div className="card-details">
                <div className="title input-element">
                    <TextField fullWidth={true} variant="standard"
                               value={instanceToUpdate.title}
                               placeholder="Title..."
                               onChange={handleInstanceTitleChange}
                    />
                </div>
                <div className="description input-element">
                    <TextField fullWidth={true} variant="standard"
                               value={instanceToUpdate.subtitle || ""}
                               placeholder="Subtitle..."
                               onChange={handleInstanceSubtitleChange}
                    />
                </div>
                <div className="url input-element">
                    <TextField fullWidth={true} variant="standard"
                               value={newInstanceUrl}
                               placeholder="https://my-photos.be"
                               onChange={handleNewInstanceUrlChange}
                               InputProps={{
                                   endAdornment: (
                                       <InputAdornment position="end">
                                           <IconButton color="warning"
                                                       onClick={handleAddNewInstanceUrl}>
                                               <AddCircle/>
                                           </IconButton>
                                       </InputAdornment>
                                   )
                               }}/>
                    <div className="instance-links">
                        {instanceToUpdate.urls.map((url, idx) => <div className="instance-link" key={idx}>
                            <IconButton color="error" onClick={(e) => handleDeleteUrl(e, url)}>
                                <RemoveCircle/>
                            </IconButton>
                            <NavLink to={url}>{url}</NavLink>
                        </div>)}
                    </div>
                </div>
                <div className="input-buttons">
                    <div className="left-buttons">
                        <Button className="photo-save" variant="contained" color="error"
                                onClick={handleDeleteInstance}>
                            Delete
                        </Button>
                        <Button className="photo-save" variant="contained" color="info"
                                disabled={!instanceHasChanges()}
                                onClick={handleSaveInstance}>
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </>}
    </Card>
}