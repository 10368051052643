import {useInstances} from "../../services/instances";
import * as React from "react";
import {useEffect} from "react";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import {AlbumsProvider} from "../../services/albums";
import Loader from "../../components/Loader";

export function InstanceLayout() {
    const {instanceId} = useParams();
    const navigate = useNavigate();
    const {isLoading, instances, selectedInstance, setSelectedInstance} = useInstances();

    useEffect(() => {
        console.log("[InstanceLayout]: useEffect triggered with dependencies:", { navigate, instances, selectedInstance, setSelectedInstance, instanceId, isLoading });

        if (!isLoading && instances && setSelectedInstance && instanceId) {
            const newInstance = instances[instanceId];
            if (newInstance && newInstance.isDifferent(selectedInstance)) {
                setSelectedInstance(newInstance);
                console.log("[InstanceLayout]: Selected instance has been set", {newInstance, selectedInstance});
            }
        }
    }, [navigate, instances, selectedInstance, setSelectedInstance, instanceId, isLoading]);

    useEffect(() => {
        console.log("[InstanceLayout]: Mounting instance layout");

        return () => {
            console.log("[InstanceLayout]: Unmounting instance layout");
        };
    }, []);

    useEffect(() => {
        console.log("[InstanceLayout]: Current selected instance:", {selectedInstance});
    }, [selectedInstance]);

    useEffect(() => {
        console.log("[InstanceLayout]: Current instances:", {instances});
    }, [instances]);

    console.log("[InstanceLayout]: Rendering instance layout")
    return <>
        <AlbumsProvider>
            <Loader loading={isLoading}/>
            {!isLoading && <Outlet />}
        </AlbumsProvider>
    </>
}
