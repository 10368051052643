import * as React from "react";
import {useInstances} from "../../services/instances";
import InstanceCardEditor from "../../components/instance-card-editor";
import InstanceContactInfoEditor from "../../components/instance-contact-info-editor";
import InstanceLayoutEditor from "../../components/instance-layout-editor";
import InstanceThemeEditor from "../../components/instance-theme-editor";

export function InstanceSettingsPage() {
    const {selectedInstance, instanceApiError} = useInstances();

    return <>
        {instanceApiError && <div className="error-box">{instanceApiError}</div>}
        {selectedInstance && <>
            <h1>General</h1>
            <InstanceCardEditor instance={selectedInstance}/>
            <h1>Layout</h1>
            <InstanceLayoutEditor instance={selectedInstance}/>
            <h1>Theme</h1>
            <InstanceThemeEditor instance={selectedInstance}/>
            <h1>Contact</h1>
            <p>
                If a contact option is left empty, it will not be shown on the website.
            </p>
            <InstanceContactInfoEditor instance={selectedInstance}/>
        </>}
    </>;
}