import * as React from "react";
import {useNavigate} from "react-router-dom";
import {ArrowCircleLeftOutlined, MoveDown, MoveUp} from "@mui/icons-material";
import {useEffect, useState} from "react";
import {Box, Button, ButtonGroup, Divider, FormLabel, IconButton, Input, TextField} from "@mui/material";
import {Photo} from "../../models/photo";
import {Album} from "../../models/album";

import "./instance-album-page.scss";
import {useInstances} from "../../services/instances";
import {useAlbums} from "../../services/albums";
import {EditImageCard, ImageCard} from "../../components/Image-card";

function InstanceAlbumPage() {
    const {selectedInstance} = useInstances();
    const {
        albumsApiError,
        selectedAlbum,
        patchAlbum,
        patchPhoto,
        uploadPhotos,
        deletePhoto,
        movePhotoDown,
        movePhotoUp
    } = useAlbums();

    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [sort, setSort] = useState('');

    const [photosToUpdate, setPhotosToUpdate] = useState([]);
    const [apiError, setApiError] = useState(null);

    useEffect(() => {
        if (selectedAlbum) {
            if (name === '') {
                setName(selectedAlbum.name || '');
            }
            if (description === '') {
                setDescription(selectedAlbum.description || '');
            }
            if (sort === '') {
                setSort(selectedAlbum.sort || '');
            }
            if (photosToUpdate.length === 0) {
                setPhotosToUpdate(selectedAlbum.photos.map(x => new Photo(x)));
            } else {
                const finalPhotosToUpdate = [];
                for (const photo of selectedAlbum.photos) {
                    const updatedPhotoIndex = photosToUpdate.findIndex(p => p.photoId === photo.photoId);
                    if (updatedPhotoIndex >= 0) {
                        finalPhotosToUpdate.push(photosToUpdate[updatedPhotoIndex]);
                    } else {
                        finalPhotosToUpdate.push(photo);
                    }
                }
                if (JSON.stringify(photosToUpdate) !== JSON.stringify(finalPhotosToUpdate)) {
                    setPhotosToUpdate(finalPhotosToUpdate);
                }
            }
        }
    }, [selectedAlbum, description, name, sort, photosToUpdate]);

    const handleSaveEdit = async () => {
        try {
            if (hasChanges()) {
                setApiError(null);
                await patchAlbum(new Album({
                    ...selectedAlbum,
                    name,
                    description
                }));
            }
        } catch (err) {
            if (typeof err === "string" && err.toLowerCase().indexOf("duplicate") >= 0) {
                setApiError("Name already in use");
            } else {
                console.error(err);
                setApiError("An error occurred trying to save the album, contact the system administrator");
            }
        }
    }

    const handleSavePhotoEdit = async (idx) => {
        try {
            setApiError(null);
            if (photoHasChanges(idx)) {
                await patchPhoto(
                    photosToUpdate[idx]
                );
            }
        } catch (err) {
            if (typeof err === "string" && err.toLowerCase().indexOf("duplicate") >= 0) {
                setApiError("Name already in use");
            } else {
                console.error(err);
                setApiError("An error occurred trying to save the photo, contact the system administrator");
            }
        }
    }

    const handleMovePhotoDown = async (idx) => {
        try {
            await movePhotoDown(
                photosToUpdate[idx].photoId
            );
        } catch (err) {
            console.error(err);
            setApiError("An error occurred trying to save the photo, contact the system administrator");
        }
    }

    const handleMovePhotoUp = async (idx) => {
        try {
            await movePhotoUp(
                photosToUpdate[idx].photoId
            );
        } catch (err) {
            console.error(err);
            setApiError("An error occurred trying to save the photo, contact the system administrator");
        }
    }

    const handleDeletePhoto = async (e, photoId) => {
        try {
            await deletePhoto(photoId);
        } catch (err) {
            setApiError("An error occurred trying to save the photo, contact the system administrator");
        }
    }

    const handlePhotoNameChange = (photoIndex, e) => {
        photosToUpdate[photoIndex].name = e.target.value;
        setPhotosToUpdate([...photosToUpdate]);
    }

    const handlePhotoDescriptionChange = (photoIndex, e) => {
        photosToUpdate[photoIndex].description = e.target.value;
        setPhotosToUpdate([...photosToUpdate]);
    }

    const hasChanges = () =>
        (selectedAlbum.sort || '') !== (sort || '') ||
        (selectedAlbum.name || '') !== (name || '') ||
        (selectedAlbum.description || '') !== (description || '');

    const photoHasChanges = (idx) => {
        if (photosToUpdate && selectedAlbum) {
            return (photosToUpdate[idx].name || "") !== (selectedAlbum.photos[idx]?.name || "") ||
                (photosToUpdate[idx].description || "") !== (selectedAlbum.photos[idx]?.description || "");
        }
        return false;
    }

    const handleNavigateToOverview = () => {
        navigate(`/${selectedInstance.instanceId}/albums`);
    }

    const handleFiles = async (files) => {
        const formData = new FormData();
        for (let file of files) {
            formData.append('photos', file);
        }
        await uploadPhotos(formData);
    }

    return selectedAlbum && <Box id="admin-page-manage-albums">
        <Box id="page-title">
            <Box id="left-part">
                <IconButton onClick={handleNavigateToOverview} color="primary">
                    <ArrowCircleLeftOutlined id="navigate-back"/>
                </IconButton>
                <Box id="edit-title" className="input-element">
                    <Input onKeyUp={(e) => e.key === "Enter" && handleSaveEdit(e)}
                           fullWidth={true}
                           type="text"
                           onChange={(e) => setName(e.target.value)}
                           placeholder="Name..."
                           tabIndex={1}
                           value={name}
                    />
                </Box>
                <Box className="photos-count">({selectedAlbum.photos.length} photos)</Box>
            </Box>
        </Box>
        {apiError && <Box className="error-box">{apiError}</Box>}
        {albumsApiError && <Box className="error-box">{albumsApiError}</Box>}
        {selectedAlbum && <Box>
            <Box id="album-description">
                <Box className="input-element">
                    <FormLabel htmlFor="description">Description</FormLabel>
                    <TextField id="description" multiline={true} fullWidth={true} rows={2}
                               onChange={(e) => setDescription(e.target.value)}
                               placeholder="Description..."
                               tabIndex={2}
                               value={description}
                    />
                </Box>
            </Box>
            <Box className="save-button">
                <Button id="page-save" variant="contained" color="info" disabled={!hasChanges()}
                        onClick={handleSaveEdit}>
                    Save
                </Button>
            </Box>
            {selectedAlbum?.albumId &&
                <Box id="photo-list">
                    <Box className="photo photo--new">
                        <EditImageCard sx={{height: '200px', width: '100%'}} onFilesUploaded={handleFiles}/>
                    </Box>
                    {photosToUpdate?.map((photo, idx) =>
                        <Box className="card-layout photo" key={photo.photoId}>
                            <ImageCard sx={{marginRight: '25px'}} photo={photo} includeFileName={true}/>
                            <Box className="card-details"
                                 sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                                <Box>
                                    <Box className="title input-element">
                                        <TextField fullWidth={true} variant="standard"
                                                   value={photo.name}
                                                   onChange={(e) => handlePhotoNameChange(idx, e)}
                                        />
                                    </Box>
                                    <Box className="description input-element">
                                        <TextField multiline={true} fullWidth={true} rows={2}
                                                   placeholder={"Description"}
                                                   onChange={(e) => handlePhotoDescriptionChange(idx, e)}
                                                   value={photo.description || ""}
                                        />
                                    </Box>
                                </Box>
                                <Box className="input-buttons">
                                    <Box className="left-buttons">
                                        <Button className="photo-save" variant="contained" color="error"
                                                onClick={(e) => handleDeletePhoto(e, photo.photoId)}>
                                            Delete
                                        </Button>
                                        <Button className="photo-save" variant="contained" color="info"
                                                disabled={!photoHasChanges(idx)}
                                                onClick={() => handleSavePhotoEdit(idx)}>
                                            Save
                                        </Button>
                                    </Box>
                                    <ButtonGroup variant="contained">
                                        <IconButton
                                            style={{visibility: (idx > 0 ? "visible" : "hidden")}}
                                            variant="contained"
                                            onClick={() => handleMovePhotoUp(idx)}>
                                            <MoveUp color="info"/>
                                        </IconButton>
                                        <Divider orientation="vertical" variant="middle" flexItem/>
                                        <IconButton
                                            style={{visibility: (idx < photosToUpdate.length - 1 ? "visible" : "hidden")}}
                                            variant="contained"
                                            onClick={() => handleMovePhotoDown(idx)}>
                                            <MoveDown color="info"/>
                                        </IconButton>
                                    </ButtonGroup>
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Box>
            }
        </Box>}
    </Box>;
}

export default InstanceAlbumPage;