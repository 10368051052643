import {Photo} from "./photo.js";

export class Album {
    /**
     * @param {Album} [album]
     */
    constructor(album) {
        this._id = album?._id;
        this.albumId = album?.albumId;
        this.name = album?.name;
        this.description = album?.description;
        this.sort = album?.sort;
        this.sortOrder = album?.sortOrder;
        this.photos = album?.photos?.map(photo => new Photo(photo)) || [];
        this.coverPhoto = this.photos.length > 0 ? this.photos[0] : null;
    }

    /** @type {string} **/
    id;
    /** @type {string} **/
    albumId;
    /** @type {string} **/
    name;
    /** @type {string} **/
    description;
    /** @type {"manual"|"auto"} **/
    sort;
    /** @type {number} **/
    sortOrder;
    /** @type {Photo[]} **/
    photos;
    /** @type {Photo} **/
    coverPhoto;

    isDifferent(album) {
        if(!album) return true;
    }
}