import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import useInstancesApi from "./instances-api";
import {useAuth} from "../authentication/use-auth";

/**
 * @typedef {Object} InstancesContextValue
 * @property {boolean} isLoading - Indicates if instances are loading.
 * @property {Object<string, Instance>} instances - A dictionary of instances indexed by their IDs.
 * @property {Instance | null} selectedInstance - The currently selected instance.
 * @property {function(Instance): Promise<void>} createInstance - Creates a new instance.
 * @property {function(string): Promise<void>} deleteInstance - Deletes an instance by ID.
 * @property {function(Instance): Promise<void>} updateInstance - Updates an existing instance.
 * @property {function(string, FormData): Promise<void>} uploadCoverPhoto - Uploads a cover photo for an instance.
 * @property {function(string, string): Promise<Page>} addPage - Adds a new page to an instance.
 * @property {function(pageId: string, page: Page): Promise<Page>} updatePage - Updates a page in an instance.
 * @property {function(pageId: string, formData: FormData): Promise<Page>} uploadPageCoverPhoto - Updates a page in an instance.
 * @property {string | null} instanceApiError - Any error related to the instance API.
 */

export const InstancesContext = React.createContext(
    undefined
);

/**
 * Provider component for instances context.
 * @param {Object} props
 * @param {React.ReactNode} props.children - The child components to be rendered within the provider.
 * @returns {JSX.Element}
 */
export const InstancesProvider = ({children}) => {
    const {userData} = useAuth();
    const {
        fetchInstances,
        createInstance,
        deleteInstance,
        updateInstance,
        addPage,
        updatePage,
        uploadPageCoverPhoto,
        uploadCoverPhoto,
        instanceApiError
    } = useInstancesApi();

    const [isLoading, setIsLoading] = useState(false);
    const [instances, setInstances] = useState(null);
    const [selectedInstance, setSelectedInstance] = useState(null);

    const updateInstances = useCallback(async () => {
        try {
            setIsLoading(true);
            const arrInstances = await fetchInstances();
            const dictInstances = {};
            for (const instance of arrInstances) {
                dictInstances[instance.instanceId] = instance;
            }
            console.log("[InstancesContext]: Setting the instances", {dictInstances})
            setInstances(dictInstances);
        } catch(err){
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }, [fetchInstances]);

    useEffect(() => {
        console.log("[InstancesContext]: useEffect - dependencies:", {userData, instances, fetchInstances, updateInstances, isLoading});
        if (!isLoading && userData && fetchInstances && instances === null) {
            updateInstances();
        }
    }, [userData, instances, fetchInstances, updateInstances, isLoading]);

    useEffect(() => {
        console.log("[InstancesContext]: useEffect - dependencies:", {instances});
    }, [instances]);

    useEffect(() => {
        console.log("[InstancesContext]: useEffect - dependencies:", {selectedInstance});
    }, [selectedInstance]);

    const handleCreateInstance = useCallback(async (instance) => {
        const newInstance = await createInstance(instance);
        setInstances({
            ...instances,
            [instance.instanceId]: newInstance
        });
    }, [createInstance, instances]);

    const handleUpdateInstance = useCallback(async (instance) => {
        const updatedInstance = await updateInstance(instance);
        setInstances({
            ...instances,
            [instance.instanceId]: updatedInstance
        });
        if (selectedInstance.instanceId === updatedInstance.instanceId) {
            setSelectedInstance(updatedInstance);
        }
    }, [updateInstance, selectedInstance, instances]);

    const handleAddPage = useCallback(async (instanceId, title) => {
        const updatedInstance = await addPage(instanceId, title);
        setInstances({
            ...instances,
            [instanceId]: updatedInstance
        });
        if (selectedInstance.instanceId === instanceId) {
            setSelectedInstance(updatedInstance);
        }
        const pages = Object.values(updatedInstance.pages);
        return pages[pages.length - 1];
    }, [addPage, selectedInstance, instances]);

    const handleUpdatePage = useCallback(async (pageId, updatedPage) => {
        const updatedInstance = await updatePage(selectedInstance.instanceId, pageId, updatedPage);
        setInstances({
            ...instances,
            [selectedInstance.instanceId]: updatedInstance
        });
        setSelectedInstance(updatedInstance);
        return updatedInstance.pages[pageId];
    }, [updatePage, selectedInstance, instances]);

    const handleUploadPageCoverPhoto = useCallback(async (pageId, formData) => {
        const updatedInstance = await uploadPageCoverPhoto(selectedInstance.instanceId, pageId, formData);
        setInstances({
            ...instances,
            [selectedInstance.instanceId]: updatedInstance
        });
        setSelectedInstance(updatedInstance);
        return updatedInstance.pages[pageId];
    }, [uploadPageCoverPhoto, selectedInstance, instances]);

    const handleUpdateCoverPhoto = useCallback(async (instanceId, formData) => {
        const updatedInstance = await uploadCoverPhoto(instanceId, formData);
        setInstances({
            ...instances,
            [instanceId]: updatedInstance
        });
        if (selectedInstance.instanceId === instanceId) {
            setSelectedInstance(updatedInstance);
        }
    }, [uploadCoverPhoto, selectedInstance, instances]);

    const handleDeleteInstance = useCallback(async (instanceId) => {
        await deleteInstance(instanceId);

        delete instances[instanceId];
        setInstances([...instances]);

        if (selectedInstance.instanceId === instanceId) {
            setSelectedInstance(null);
        }
    }, [deleteInstance, selectedInstance, instances]);

    const value = useMemo(() => ({
        isLoading,
        instances,
        selectedInstance,
        setSelectedInstance,
        createInstance: handleCreateInstance,
        deleteInstance: handleDeleteInstance,
        updateInstance: handleUpdateInstance,
        uploadCoverPhoto: handleUpdateCoverPhoto,
        addPage: handleAddPage,
        updatePage: handleUpdatePage,
        uploadPageCoverPhoto: handleUploadPageCoverPhoto,
        instanceApiError,
    }), [
        isLoading,
        instances,
        selectedInstance,
        setSelectedInstance,
        handleCreateInstance,
        handleDeleteInstance,
        handleUpdateCoverPhoto,
        handleUpdateInstance,
        handleUploadPageCoverPhoto,
        handleAddPage,
        handleUpdatePage,
        instanceApiError
    ]);

    return <InstancesContext.Provider value={value}>{children}</InstancesContext.Provider>;
};


/**
 * Hook to access instances context.
 * @returns {InstancesContextValue | undefined}
 */
export const useInstances = () => {
    return useContext(InstancesContext);
};