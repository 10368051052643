import {useState} from "react";
import {Instance} from "../models/instance";
import {
    Box,
    Button,
    Card,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup
} from "@mui/material";
import * as React from "react";
import {useInstances} from "../services/instances";

export default function InstanceThemeEditor({instance}) {
    const {updateInstance} = useInstances();

    const [selectedTheme, setSelectedTheme] = useState(instance.theme || 'light');
    const handleSelectLayout = (event) => {
        setSelectedTheme(event.target.value);
    }
    const instanceHasChanges = () => {
        return instance.theme !== selectedTheme;
    }
    const handleSaveInstance = async () => {
        if (instanceHasChanges()) {
            await updateInstance(new Instance({
                ...instance,
                theme: selectedTheme
            }));
        }
    }
    const themes = [{
        id: "dark",
        name: "Dark"
    }, {
        id: "light",
        name: "Light"
    }]
    return <>
        <Card sx={{padding: '15px'}}>
            <FormControl>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={selectedTheme}
                    onChange={handleSelectLayout}
                    name="radio-buttons-group"
                >
                    {themes.map(layout =>
                        <FormControlLabel key={layout.id} value={layout.id} control={<Radio/>} label={layout.name}/>
                    )}
                </RadioGroup>
            </FormControl>
            <Box className="input-buttons">
                <Box className="left-buttons">
                    <Button className="photo-save" variant="contained" color="info"
                            disabled={!instanceHasChanges()}
                            onClick={handleSaveInstance}>
                        Save
                    </Button>
                </Box>
            </Box>
        </Card>
    </>
}