import {useAlbums} from "../../services/albums";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import * as React from "react";
import {useEffect} from "react";
import {useInstances} from "../../services/instances";
import Loader from "../../components/Loader";

export function AlbumsLayout() {
    const navigate = useNavigate();
    const {albumId} = useParams();

    const {selectedInstance} = useInstances();
    const {albums, selectedAlbum, setSelectedAlbum} = useAlbums();

    useEffect(() => {
        if (albumId) {
            if (albums && selectedInstance && (!selectedAlbum || selectedAlbum.albumId !== albumId)) {
                const selectedAlbum = albums[selectedInstance.instanceId]?.[albumId];
                if (selectedAlbum) {
                    console.log("Setting selected album", albums, albumId, selectedAlbum);
                    setSelectedAlbum(selectedAlbum);
                } else {
                    console.log("Navigating to root");
                    navigate(`/${selectedInstance.instanceId}/albums`);
                }
            }
        } else {
            setSelectedAlbum(null);
        }
    }, [navigate, albumId, albums, setSelectedAlbum, selectedAlbum, selectedInstance]);

    return <>
        {selectedInstance && <Outlet />}
        {!selectedInstance && <Loader />}
    </>
}