import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import useAlbumsApi from "./albums-api";
import {useInstances} from "../instances";

/**
 * @typedef {Object} AlbumsContextValue
 * @property {boolean} isLoading - Indicates if albums are currently loading.
 * @property {string | null} albumsApiError - Any error related to the albums API.
 * @property {function(error: string): void} setAlbumsApiError - Any error related to the albums API.
 * @property {{[string]: {[string]: Album}}} albums - The collection of albums, keyed by instance ID.
 * @property {Album | null} selectedAlbum - The currently selected album.
 * @property {function(album: Album | null): void} setSelectedAlbum - Sets the currently selected album.
 * @property {function(name: string): Promise<Album>} createAlbum - Creates a new album with the given title.
 * @property {function(album: Album): Promise<Album>} patchAlbum - Updates an existing album.
 * @property {function(albumId: string): Promise<void>} deleteAlbum - Deletes an album by ID.
 * @property {function(albumId: string): Promise<Album[]>} moveAlbumDown - Moves an album down in the order.
 * @property {function(albumId: string): Promise<Album[]>} moveAlbumUp - Moves an album up in the order.
 * @property {function(formData: FormData): Promise<Album>} uploadPhotos - Uploads photos to the selected album.
 * @property {function(photoId: string): Promise<Album>} deletePhoto - Deletes a photo from the selected album.
 * @property {function(patchOperations: {patch}[]): Promise<Album>} patchPhoto - Updates a photo in the selected album.
 * @property {function(photoId: string): Promise<Album>} movePhotoDown - Moves a photo down in the order within the album.
 * @property {function(photoId: string): Promise<Album>} movePhotoUp - Moves a photo up in the order within the album.
 */

export const AlbumsContext = React.createContext(
    undefined
);

/**
 * Albums provider component that manages albums-related state and API calls.
 * @param {Object} props
 * @param {React.ReactNode} props.children - The child components.
 * @returns {JSX.Element}
 */
export const AlbumsProvider = ({children}) => {
    const {selectedInstance} = useInstances();
    const [albumsApiError, setAlbumsApiError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const {
        fetchAlbums,
        createAlbum,
        patchAlbum,
        deleteAlbum,
        moveAlbumDown,
        moveAlbumUp,
        uploadPhotos,
        patchPhoto,
        deletePhoto,
        movePhotoDown,
        movePhotoUp
    } = useAlbumsApi();

    const [albums, setAlbums] = useState(null);
    const [selectedAlbum, setSelectedAlbum] = useState(null);

    const updateInstanceAlbums = useCallback((instanceId, instanceAlbums, albumId = null) => {
        const dictInstanceAlbums = {};
        for (const album of instanceAlbums) {
            dictInstanceAlbums[album.albumId] = album;
        }
        setAlbums({
            ...albums,
            [instanceId]: dictInstanceAlbums
        });
        if (selectedAlbum && selectedAlbum.isDifferent(dictInstanceAlbums[albumId])) {
            setSelectedAlbum(dictInstanceAlbums[albumId]);
        }
    }, [albums, selectedAlbum, setSelectedAlbum]);

    const updateInstanceAlbum = useCallback((instanceId, album) => {
        if (albums) {
            console.log("Settings albums")
            setAlbums({
                ...albums,
                [instanceId]: {
                    ...albums[instanceId],
                    [album.albumId]: album
                }
            });
            console.log("Updating selected album")
            if (selectedAlbum && selectedAlbum.albumId === album.albumId) {
                setSelectedAlbum(album);
            }
        }
    }, [albums, selectedAlbum]);

    const fetchInstanceAlbums = useCallback(async (instanceId) => {
        if (setIsLoading && fetchAlbums && updateInstanceAlbums) {
            if (!isLoading) {
                setIsLoading(true);
                const currentDict = albums?.[instanceId];
                if (!currentDict) {
                    try {
                        const arrAlbums = await fetchAlbums(instanceId);
                        updateInstanceAlbums(instanceId, arrAlbums);
                    } catch (err) {
                        console.error(err)
                    }
                }
                setIsLoading(false);
            }
        }
    }, [setIsLoading, isLoading, fetchAlbums, albums, updateInstanceAlbums]);

    useEffect(() => {
        if (selectedInstance && fetchInstanceAlbums) {
            fetchInstanceAlbums(selectedInstance.instanceId)
        }
    }, [selectedInstance, fetchInstanceAlbums]);

    const handleCreateAlbum = useCallback(async (name) => {
        const instanceId = selectedInstance.instanceId;

        console.log("Creating album with name", name);
        const newAlbum = await createAlbum(instanceId, name);
        if (newAlbum) {
            updateInstanceAlbum(instanceId, newAlbum);
        }
        return newAlbum;
    }, [createAlbum, updateInstanceAlbum, selectedInstance]);

    const handlePatchAlbum = useCallback(async (album) => {
        try {
            const instanceId = selectedInstance.instanceId;
            const updatedAlbum = await patchAlbum(instanceId, album)
            updateInstanceAlbum(instanceId, updatedAlbum);
        } catch (err) {
            if (typeof err === "string" && err.toLowerCase().indexOf("duplicate") >= 0) {
                setAlbumsApiError("Name already in use");
            } else {
                throw err;
            }
        }
    }, [patchAlbum, selectedInstance, updateInstanceAlbum, setAlbumsApiError]);

    const handleDeleteAlbum = useCallback(async (albumId) => {
        const instanceId = selectedInstance.instanceId;

        await deleteAlbum(instanceId, albumId);

        delete albums[instanceId][albumId];
        setAlbums({...albums})

        if (selectedAlbum?.albumId === albumId) {
            setSelectedAlbum(null);
        }
    }, [deleteAlbum, albums, selectedAlbum, selectedInstance]);

    const handleMoveAlbumDown = useCallback(async (albumId) => {
        const instanceId = selectedInstance.instanceId;

        const updatedAlbums = await moveAlbumDown(instanceId, albumId);
        updateInstanceAlbums(instanceId, updatedAlbums, albumId);
    }, [moveAlbumDown, updateInstanceAlbums, selectedInstance])

    const handleMoveAlbumUp = useCallback(async (albumId) => {
        const instanceId = selectedInstance.instanceId;

        const updatedAlbums = await moveAlbumUp(instanceId, albumId);
        updateInstanceAlbums(instanceId, updatedAlbums, albumId);
    }, [moveAlbumUp, updateInstanceAlbums, selectedInstance]);

    const handleUploadPhotos = useCallback(async (formData) => {
        const instanceId = selectedInstance.instanceId;
        const albumId = selectedAlbum.albumId;

        const updatedAlbum = await uploadPhotos(instanceId, albumId, formData);
        if (updatedAlbum) {
            updateInstanceAlbum(instanceId, updatedAlbum);
            return updatedAlbum;
        }
    }, [uploadPhotos, updateInstanceAlbum, selectedAlbum, selectedInstance]);

    const handleDeletePhoto = useCallback(async (photoId) => {
        const instanceId = selectedInstance.instanceId;
        const albumId = selectedAlbum.albumId;

        const updatedAlbum = await deletePhoto(instanceId, albumId, photoId);
        if (updatedAlbum) {
            updateInstanceAlbum(instanceId, updatedAlbum);
            return updatedAlbum;
        }
    }, [deletePhoto, updateInstanceAlbum, selectedAlbum, selectedInstance]);

    const handlePatchPhoto = useCallback(async (patchOperations) => {
        const instanceId = selectedInstance.instanceId;
        const albumId = selectedAlbum.albumId;

        const updatedAlbum = await patchPhoto(instanceId, albumId, patchOperations)
        updateInstanceAlbum(instanceId, updatedAlbum);

    }, [patchPhoto, updateInstanceAlbum, selectedAlbum, selectedInstance]);

    const handleMovePhotoDown = useCallback(async (photoId) => {
        const instanceId = selectedInstance.instanceId;
        const albumId = selectedAlbum.albumId;

        const updatedAlbum = await movePhotoDown(instanceId, albumId, photoId)
        updateInstanceAlbum(instanceId, updatedAlbum);

    }, [movePhotoDown, updateInstanceAlbum, selectedAlbum, selectedInstance]);

    const handleMovePhotoUp = useCallback(async (photoId) => {
        const instanceId = selectedInstance.instanceId;
        const albumId = selectedAlbum.albumId;

        const updatedAlbum = await movePhotoUp(instanceId, albumId, photoId)
        updateInstanceAlbum(instanceId, updatedAlbum);

    }, [movePhotoUp, updateInstanceAlbum, selectedAlbum, selectedInstance]);

    const value = useMemo(() => ({
        isLoading,
        albums,
        selectedAlbum,
        setSelectedAlbum,
        createAlbum: handleCreateAlbum,
        patchAlbum: handlePatchAlbum,
        deleteAlbum: handleDeleteAlbum,
        moveAlbumDown: handleMoveAlbumDown,
        moveAlbumUp: handleMoveAlbumUp,
        uploadPhotos: handleUploadPhotos,
        deletePhoto: handleDeletePhoto,
        patchPhoto: handlePatchPhoto,
        movePhotoDown: handleMovePhotoDown,
        movePhotoUp: handleMovePhotoUp,
        albumsApiError,
    }), [
        isLoading,
        albums,
        selectedAlbum,
        setSelectedAlbum,
        handleCreateAlbum,
        handlePatchAlbum,
        handleDeleteAlbum,
        handleMoveAlbumDown,
        handleMoveAlbumUp,
        handleUploadPhotos,
        handleDeletePhoto,
        handlePatchPhoto,
        handleMovePhotoDown,
        handleMovePhotoUp,
        albumsApiError
    ]);
    return <AlbumsContext.Provider value={value}>{children}</AlbumsContext.Provider>;
};


/**
 * Hook to access albums context.
 * @returns {AlbumsContextValue | undefined}
 */
export const useAlbums = () => {
    return useContext(AlbumsContext);
};