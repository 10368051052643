import * as React from "react";
import {Box, Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {ImageCard} from "./Image-card";

export default function InstanceCard({instance}) {
    const navigate = useNavigate();
    const titleStyle = {
        fontSize: '36px',
        margin: 0,
        marginBottom: '22px',
        fontWeight: 700,
        letterSpacing: '0.1em',
        color: '#333'
    };
    const subtitleStyle = {
        fontSize: '18px',
        lineHeight: '1.7em',
        margin: 0,
        marginBottom: '36px',
        fontWeight: 400,
        letterSpacing: '0.1em',
        color: '#333'
    };
    return <Box className="card-layout photo">
        <ImageCard sx={{marginRight: '25px'}} onClick={() => navigate(`/${instance.instanceId}/albums`)} photo={instance.coverPhoto} />
        {/*{instance.coverPhoto && <Box sx={{marginRight: '15px'}} className={`cover-photo cover-photo--bordered`} onClick={() => navigate(`/${instance.instanceId}`)}>*/}
        {/*    <img src={`${instance.coverPhoto.url}?width=350&height=350&fit=cover`} alt={instance.title}/>*/}
        {/*</Box>}*/}
        <Box className="card-details">
            <h1 style={titleStyle}>{instance.title}</h1>
            <h2 style={subtitleStyle}>{instance.subtitle}</h2>
            <Button variant="contained" color="info" href={`/${instance.instanceId}`}>Configure</Button>
        </Box>
    </Box>;
}