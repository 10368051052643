import {Button, Card, IconButton, InputAdornment, TextField} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import {Instance} from "../models/instance";
import {AddCircle, Email, Facebook, Instagram, LinkedIn, Phone, RemoveCircle} from "@mui/icons-material";
import {useInstances} from "../services/instances";

export default function InstanceContactInfoEditor({instance}) {
    const {
        updateInstance
    } = useInstances();

    const [instanceToUpdate, setInstanceToUpdate] = useState(new Instance(instance));
    useEffect(() => {
        if (instance.instanceId !== instanceToUpdate?.instanceId) {
            setInstanceToUpdate(new Instance(instance));
        }
    }, [instance, instanceToUpdate]);

    const handleContactInformationChange = (e, contactInformationType) => {
        setInstanceToUpdate(new Instance({
            ...instanceToUpdate,
            contactDetails: {
                ...instanceToUpdate.contactDetails,
                [contactInformationType]: e.target.value
            }
        }));
    }
    const [newContactDetail, setNewContactDetail] = useState("");

    const instanceHasChanges = () => {
        return Object.entries(instanceToUpdate.contactDetails).filter(([typeName, value]) => {
                const currentValue = instance.contactDetails[typeName];
                return (value && !currentValue) ||
                    (!value && currentValue) ||
                    (value && currentValue && instance.contactDetails[typeName] !== value)
            }
        ).length > 0 ||
        JSON.stringify(instanceToUpdate.otherContactDetails) !== JSON.stringify(instance.otherContactDetails);
    }
    const handleOtherContactDetailChange = (e) => {
        setNewContactDetail(e.target.value);
    }
    const handleAddOtherContactDetail = () => {
        setInstanceToUpdate(new Instance({
            ...instance,
            otherContactDetails: [...instanceToUpdate.otherContactDetails, newContactDetail]
        }));
        setNewContactDetail("");
    }
    const handleDeleteOtherContactDetail = (e, contactDetail) => {
        setInstanceToUpdate(new Instance({
            ...instanceToUpdate,
            otherContactDetails: instanceToUpdate.otherContactDetails.filter(cd => cd !== contactDetail)
        }));
    }

    const handleSaveInstance = async () => {
        if (instanceHasChanges()) {
            await updateInstance(instanceToUpdate);
        }
    }

    const supportedContactTypes = {
        'email': {
            icon: <Email/>,
            placeholder: "john.doe@example.com"
        },
        'instagram': {
            icon: <Instagram/>,
            placeholder: "https://www.instagram.com/john-doe"
        },
        'facebook': {
            icon: <Facebook/>,
            placeholder: "https://www.facebook.com/john-doe"
        },
        'linkedIn': {
            icon: <LinkedIn/>,
            placeholder: "https://www.linkedin.com/john-doe"
        },
        'phone': {
            icon: <Phone/>,
            placeholder: "+32 123 45 67 89"
        }
    }

    return <Card sx={{padding: '15px'}}>
        <div className="card-layout photo">
            <div className="card-details">
                {Object.entries(supportedContactTypes).map(([typeName, typeObj]) =>
                    <div key={typeName} className={`${typeName} input-element`}>
                        <TextField fullWidth={true} variant="standard"
                                   value={instanceToUpdate.contactDetails[typeName] || ""}
                                   placeholder={typeObj.placeholder || typeName}
                                   onChange={(e) => handleContactInformationChange(e, typeName)}
                                   InputProps={{
                                       startAdornment: (
                                           <InputAdornment position="start">
                                               {typeObj.icon}
                                           </InputAdornment>
                                       )
                                   }}
                        />
                    </div>
                )}
                <div className="other-contact-details input-element">
                    <TextField fullWidth={true} variant="standard"
                               value={newContactDetail}
                               placeholder="New contact detail"
                               onChange={handleOtherContactDetailChange}
                               InputProps={{
                                   endAdornment: (
                                       <InputAdornment position="end">
                                           <IconButton color="warning"
                                                       onClick={handleAddOtherContactDetail}>
                                               <AddCircle/>
                                           </IconButton>
                                       </InputAdornment>
                                   )
                               }}/>
                    <div className="instance-links">
                        {instanceToUpdate.otherContactDetails.map((cd, idx) => <div className="instance-other-contact-detail" key={idx}>
                            <IconButton color="error" onClick={(e) => handleDeleteOtherContactDetail(e, cd)}>
                                <RemoveCircle/>
                            </IconButton>
                            {cd}
                        </div>)}
                    </div>
                </div>
                <div className="input-buttons">
                    <Button className="photo-save" variant="contained" color="info"
                            disabled={!instanceHasChanges()}
                            onClick={handleSaveInstance}>
                        Save
                    </Button>
                </div>
            </div>
        </div>
    </Card>;
}