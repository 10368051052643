import * as React from "react";
import {Link, useNavigate} from "react-router-dom";
import {useState} from "react";
import {AccountCircle, AddCircle, Settings} from '@mui/icons-material';
import {
    AppBar,
    Avatar,
    Box,
    Button,
    Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Menu,
    MenuItem,
    Select, TextField,
    Toolbar, Typography
} from "@mui/material";

import "./header.scss";
import {useAuth} from "../services/authentication/use-auth";
import {useInstances} from "../services/instances";

export default function Header() {
    const {instances, selectedInstance, addPage} = useInstances();
    const navigate = useNavigate();
    const {userData, userManager} = useAuth();
    const [open, setOpen] = React.useState(false);

    const [anchorElUser, setAnchorElUser] = useState(null);

    const handleSignOut = async () => {
        await userManager.signoutRedirect();
        navigate("/");
        setAnchorElUser(null);
    }

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    const handleAddNewPage = () => {
        setOpen(true);
    }
    const handleCloseAddNewPage = () => {
        setOpen(false);
    };
    return <AppBar position="sticky">
        <Container maxWidth="xl">
            <Toolbar disableGutters>
                <Box sx={{flexGrow: 1, display: {xs: 'flex', alignItems: 'center'}}}>
                    {selectedInstance && <>
                        {Object.values(instances)?.length > 1 &&
                            <Select sx={{marginRight: '15px'}} className="instance-select"
                                    value={selectedInstance.instanceId}
                                    onChange={(e) => navigate(`/${e.target.value}`)} variant="outlined" size="small"
                                    color="warning">
                                {Object.values(instances).map(instance => <MenuItem key={instance.instanceId}
                                                                                    value={instance.instanceId}>
                                    {instance.instanceId}
                                </MenuItem>)}
                            </Select>}
                        {Object.values(instances)?.length === 1 &&
                            <Avatar sx={{marginRight: '15px'}} alt={selectedInstance.instanceId}
                                    src={`${selectedInstance.coverPhoto.url}?width=800&height=800&fit=cover`}/>}
                        <Button
                            component={Link}
                            to={`/${selectedInstance?.instanceId}/albums`}
                            size="medium"
                            color="inherit"
                        >
                            Albums
                        </Button>
                        {Object.values(selectedInstance?.pages || {}).map(page => <Button key={page.pageId}
                                                                                          component={Link}
                                                                                          to={`/${selectedInstance?.instanceId}/pages/${page.pageId}`}
                                                                                          size="medium"
                                                                                          color="inherit">
                            {page.title}
                        </Button>)}
                    </>
                    }
                </Box>
                <Box sx={{flexGrow: 0}}>
                    {selectedInstance && <>
                        <Button onClick={handleAddNewPage} size="medium" color="inherit">
                            <AddCircle sx={{marginRight: '5px'}}/>
                            New page
                        </Button>
                        <Button
                            component={Link}
                            variant="text"
                            to={`/${selectedInstance?.instanceId}/settings`}
                            sx={{color: 'var(--text-color)'}}
                            startIcon={<Settings/>}
                        >
                            Settings
                        </Button>
                    </>}
                    <Button
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenUserMenu}
                        color="inherit"
                        endIcon={<AccountCircle/>}
                    >
                        {userData?.profile?.email}
                    </Button>
                    <Menu
                        sx={{mt: '45px'}}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                    >
                        <MenuItem onClick={handleSignOut}>
                            <Typography sx={{textAlign: 'center'}}>Logout</Typography>
                        </MenuItem>
                    </Menu>
                </Box>
            </Toolbar>
        </Container>
        <Dialog
            open={open}
            onClose={handleCloseAddNewPage}
            PaperProps={{
                component: 'form',
                onSubmit: async (event) => {
                    event.preventDefault();
                    const formData = new FormData(event.currentTarget);
                    const formJson = Object.fromEntries(formData.entries());
                    const title = formJson.title;
                    const page = await addPage(selectedInstance.instanceId, title);
                    handleCloseAddNewPage();
                    if (page) {
                        navigate(`/${selectedInstance.instanceId}/pages/${page.pageId}`);
                    }
                },
            }}
        >
            <DialogTitle>Create page</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Choose a page title
                </DialogContentText>
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="title"
                    name="title"
                    label="Page title"
                    type="text"
                    fullWidth
                    variant="standard"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseAddNewPage}>Cancel</Button>
                <Button type="submit">Create</Button>
            </DialogActions>
        </Dialog>
    </AppBar>;
}