import {useRef, useState} from "react";
import Loader from "./Loader";
import * as React from "react";
import {Box} from "@mui/material";

export default function DragAndDrop({children, onFilesUpload, ...props}) {
    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef = useRef();

    const [isUploading, setIsUploading] = useState(false);
    const [uploadApiError, setUploadApiError] = useState(null);

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            await handleFiles(e.dataTransfer.files)
        }
    };

    const handleFiles = async (files) => {
        try {
            setUploadApiError(null);
            setIsUploading(true);
            await onFilesUpload(files);
            setIsUploading(false);
        } catch (err) {
            if (typeof err === "string" && err.toLowerCase().indexOf("already exist") >= 0) {
                setUploadApiError(err);
            } else if (typeof err === "string" && err.toLowerCase().indexOf("mimetype") >= 0) {
                setUploadApiError(err);
            } else {
                console.error(err);
                setUploadApiError("An error occurred trying to upload your photo's, contact the system administrator");
            }
        } finally {
            setIsUploading(false);
        }
    }

    const normalStyle = {
        ...props.sx,
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    };
    const draggingStyle = {...normalStyle, transition: '0.3s', scale: '1.2'};

    return (
        <Box
            sx={isDragging ? draggingStyle : normalStyle}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}  // Handle the drop event directly here
            onClick={(e) => fileInputRef.current.click(e)}
        >
            {children}
            <Loader loading={isUploading}/>
            <UploadError error={uploadApiError}/>
            <input type="file" id="photosUpload" ref={fileInputRef} multiple accept="image/*"
                   onChange={(e) => handleFiles(e.target.files)}></input>

        </Box>
    );
}

function UploadError({error}) {
    return error ? <Box className="upload-error">{error}</Box> : <></>;
}