import React, {useLayoutEffect} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {BrowserRouter, Navigate, Route, Routes, useLocation} from "react-router-dom";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import Keycloak from "./services/authentication/keycloak";
import InstanceAlbumsPage from "./pages/instance/instance-albums-page";
import InstanceAlbumPage from "./pages/instance/instance-album-page";
import {InstanceSelectorPage} from "./pages/instance/instance-selector-page";
import {NoAccessPage} from "./pages/no-access";
import {InstancesProvider} from "./services/instances";
import AdminLayout from "./layout";
import {InstanceSettingsPage} from "./pages/instance/instance-settings-page";
import InstancePageEditor from "./pages/instance/instance-page-editor";
import {InstanceLayout} from "./pages/instance/instance-layout";
import {AlbumsLayout} from "./pages/instance/albums-layout";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ScrollToTop>
                <Keycloak>
                    <InstancesProvider>
                        <AdminLayout>
                            <Routes>
                                <Route index path="/" element={<InstanceSelectorPage/>}/>
                                <Route path="/no-access" element={<NoAccessPage/>}/>
                                <Route path="/:instanceId" element={<InstanceLayout />}>
                                    <Route path="albums" element={<AlbumsLayout />}>
                                        <Route index element={<InstanceAlbumsPage/>} />
                                        <Route path=":albumId" element={<InstanceAlbumPage/>}/>
                                    </Route>
                                    <Route path="pages">
                                        <Route path=":pageId" element={<InstancePageEditor />}/>
                                    </Route>
                                    <Route path="settings" element={<InstanceSettingsPage/>}/>
                                    <Route index element={<Navigate to={"albums"} replace />}/>
                                    <Route path="*" element={<Navigate to={"albums"} replace />}/>
                                </Route>
                                <Route path="signout-keycloak" element={<Navigate to="/" replace />}/>
                                <Route path="signin-keycloak" element={<Navigate to="/" replace />}/>
                                <Route path="*" element={<Navigate to={"/no-access"} replace />}/>
                            </Routes>
                        </AdminLayout>
                    </InstancesProvider>
                </Keycloak>
            </ScrollToTop>
        </BrowserRouter>
    </React.StrictMode>
);

function ScrollToTop({children}) {
    const location = useLocation();
    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children
}
