import * as React from "react";
import Header from "./components/header";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

import "./admin.scss";
import Loader from "./components/Loader";

import {createTheme} from '@mui/material/styles';
import {Container, ThemeProvider} from "@mui/material";
import {useAuth} from "./services/authentication/use-auth";
import {useInstances} from "./services/instances";

const theme = createTheme({
    palette: {
        primary: {
            main: '#de5200'
        }
    },
});

export default function AdminLayout({children}) {

    const navigate = useNavigate();
    const {isLoading, userData, userManager, signIn} = useAuth();
    const {isLoading: instancesIsLoading} = useInstances();

    useEffect(() => {
        async function handleLogin() {
            try {
                await userManager.signinSilentCallback();
            } catch (err) {
            }
        }

        async function silentLogin() {
            try {
                await userManager.signinSilent();
            } catch (err) {
                await signIn();
            }
        }

        if (!userData && !isLoading) {
            console.log("[AdminLayout]: UseEffect - Performing silent login?")
            if (window.location.href.indexOf("/signin-keycloak") >= 0) {
                handleLogin();
            } else if (window.location.href.indexOf("/signin-keycloak") >= 0) {
                navigate("/");
            } else {
                silentLogin();
            }
        }
    }, [userData, isLoading, userManager, navigate, signIn]);

    return <ThemeProvider theme={theme}>
        <Header/>
        <Container id="admin-page" sx={{ marginBottom: '30px' }}>
            {userData ? <>
                    {(isLoading || instancesIsLoading) && <Loader />}
                    {!isLoading && !instancesIsLoading && children}
                </>
                : <Loader/>
            }
        </Container>
    </ThemeProvider>
}