import useAuthorizedApi from "../authorized-api";
import {Instance} from "../../models/instance";
import {useCallback, useMemo, useState} from "react";

const apiUrl = window._env_.REACT_APP_API_URL;
/**
 * @typedef {Object} UseInstancesApi
 * @property {function(): Promise<Instance[]>} fetchInstances - Fetches all instances.
 * @property {function(Instance): Promise<Instance>} updateInstance - Updates an existing instance.
 * @property {function(string): Promise<Instance>} deleteInstance - Deletes an instance by ID.
 * @property {function(Instance): Promise<Instance>} createInstance - Creates a new instance.
 * @property {function(string, string): Promise<Instance>} addPage - Adds a new page to an instance.
 * @property {function(instanceId: string, pageId: string, page: Page): Promise<Instance>} updatePage - Updates a page in an instance.
 * @property {function(instanceId: string, pageId: string, formData: FormData): Promise<Instance>} uploadPageCoverPhoto - Updates a page in an instance.
 * @property {function(string, FormData): Promise<Instance>} uploadCoverPhoto - Uploads a cover photo for an instance.
 * @property {string | null} instanceApiError - Any error related to the instance API.
 */

/**
 * Custom hook for managing instances API calls.
 * @returns {UseInstancesApi}
 */
export default function useInstancesApi() {
    const {get, post, put, doDelete} = useAuthorizedApi();
    const [instanceApiError, setInstanceApiError] = useState(null);

    const tryApiCall = useCallback(async (func) => {
        try {
            return await func();
        } catch (err) {
            console.error(err);
            if (typeof err === "string" && err.toLowerCase().indexOf("already exist") >= 0) {
                setInstanceApiError(err);
            } else if (typeof err === "string" && err.toLowerCase().indexOf("mimetype") >= 0) {
                setInstanceApiError(err);
            } else {
                setInstanceApiError("The API is currently unavailable, please contact the administrator");
            }
            return null;
        }
    }, []);

    /**
     * @return {Promise<Instance[]>}
     */
    const fetchInstances = useCallback(async () => tryApiCall(async () => {
        const response = await get(`${apiUrl}/api/instances`);
        return response?.data.map(inst => new Instance(inst));
    }), [get, tryApiCall]);
    /**
     *
     * @param {Instance} instance
     * @return {Promise<Instance>}
     */
    const updateInstance = useCallback(async (instance) => tryApiCall(async () => {
        const response = await put(`${apiUrl}/api/instances/${instance.instanceId}`, instance);
        return new Instance(response.data);
    }), [put, tryApiCall]);
    /**
     *
     * @param {string} instanceId
     * @return {Promise<Instance>}
     */
    const deleteInstance = useCallback(async (instanceId) => tryApiCall(async () => {
        const response = await doDelete(`${apiUrl}/api/instances/${instanceId}`);
        return new Instance(response.data);
    }), [doDelete, tryApiCall]);
    /**
     *
     * @param {Instance} instance
     * @return {Promise<Instance>}
     */
    const createInstance = useCallback((instance) => tryApiCall(async () => {
        const response = await post(`${apiUrl}/api/instances`, instance);
        return new Instance(response.data);
    }), [post, tryApiCall]);

    /**
     * @param {string} instanceId
     * @param {string} title
     * @return {Promise<Instance>}
     */
    const addPage = useCallback((instanceId, title) => tryApiCall(async () => {
        const response = await post(`${apiUrl}/api/instances/${instanceId}/pages`, {title: title});
        return new Instance(response.data);
    }), [post, tryApiCall]);

    /**
     * @param {string} instanceId
     * @param {FormData} formData
     * @return {Promise<Instance>}
     */
    const uploadCoverPhoto = useCallback((instanceId, formData) => tryApiCall(async () => {
        const response = await post(
            `${apiUrl}/api/instances/${instanceId}/upload-cover-photo`,
            formData
        );
        return new Instance(response.data);
    }), [post, tryApiCall]);
    /**
     * @param {string} instanceId
     * @param {string} pageId
     * @param {Page} updatedPage
     * @return {Promise<Instance>}
     */
    const updatePage = useCallback((instanceId, pageId, updatedPage) => tryApiCall(async () => {
        const response = await put(`${apiUrl}/api/instances/${instanceId}/pages/${pageId}`, updatedPage);
        return new Instance(response.data);
    }), [put, tryApiCall]);

    /**
     * @param {string} instanceId
     * @param {FormData} formData
     * @return {Promise<Instance>}
     */
    const uploadPageCoverPhoto = useCallback((instanceId, pageId, formData) => tryApiCall(async () => {
        const response = await post(
            `${apiUrl}/api/instances/${instanceId}/pages/${pageId}/upload-cover-photo`,
            formData
        );
        return new Instance(response.data);
    }), [post, tryApiCall]);

    return useMemo(() => ({
        fetchInstances,
        updateInstance,
        deleteInstance,
        createInstance,
        addPage,
        uploadCoverPhoto,
        updatePage,
        uploadPageCoverPhoto,
        instanceApiError,
    }), [
        fetchInstances,
        updateInstance,
        deleteInstance,
        createInstance,
        addPage,
        uploadCoverPhoto,
        updatePage,
        uploadPageCoverPhoto,
        instanceApiError
    ]);
}