import {AddAPhoto, Edit} from "@mui/icons-material";
import {Box} from "@mui/material";
import * as React from "react";
import DragAndDrop from "./drag-and-drop";

export function ImageCard({photo, onClick, includeFileName, defaultWidth, defaultHeight, ...props}) {
    const width = photo?.width || defaultWidth || 350;
    const height = photo?.height || defaultHeight || 350;

    return <PhotoBox {...props} onClick={onClick} sx={onClick ? {cursor: 'pointer', ...props.sx} : props.sx} width={width} height={height}>
        {photo
            ? <img src={`${photo.url}?width=${width}&height=${height}&fit=cover`}
                   alt={photo.name}/>
            : <AddAPhoto/>
        }
        {includeFileName && <Box className="file-name">
            {photo.fileName}
        </Box>}
    </PhotoBox>;
}

export function EditImageCard({
                                  photo,
                                  onFilesUploaded,
                                  defaultWidth,
                                  defaultHeight,
                                  ...props
                              }) {
    const width = photo?.width || defaultWidth || 350;
    const height = photo?.height || defaultHeight || 350;

    const dragNDropStyle = {
        '&:hover svg': {
            visibility: 'visible'
        }
    };
    const iconStyle = {
        position: 'absolute',
        color: 'var(--color-accent)',
        left: 'calc(50% - 32px)',
        top: 'calc(50% - 32px)',
        width: '64px',
        height: '64px',
        opacity: '0.8',
        zIndex: '888'
    };
    const editIconStyle = {
        ...iconStyle,
        visibility: 'hidden',
    }
    return <PhotoBox {...props} width={width} height={height}>
        <DragAndDrop onFilesUpload={onFilesUploaded} sx={dragNDropStyle}>
            {photo ? <>
                <img src={`${photo.url}?width=${width}&height=${height}&fit=cover`}
                     alt={photo.name}/>
                <Edit sx={editIconStyle} />
            </> : <AddAPhoto sx={iconStyle} />
            }
        </DragAndDrop>
    </PhotoBox>
}

function PhotoBox({children, width, height, ...props}) {
    return <Box {...props} sx={{
        width: `${width}px`,
        height: `${height}px`,
        borderRadius: '30px',
        boxShadow: '#333 5px 5px 5px',
        overflow: 'hidden',
        ...props.sx,
    }}>
        {children}
    </Box>
}