import {Photo} from "./photo";

export class Instance {
    /**
     * @param {Instance} instance
     */
    constructor(instance) {
        this._id = instance?._id;
        this.instanceId = instance?.instanceId;
        this.title = instance?.title;
        this.subtitle = instance?.subtitle;
        this.homeLayout = instance?.homeLayout;
        this.theme = instance?.theme;
        this.coverPhoto = instance?.coverPhoto ? new Photo(instance.coverPhoto) : null;
        this.urls = instance?.urls?.map(u => u) || [];
        this.contactDetails = instance?.contactDetails || {};
        this.otherContactDetails = instance?.otherContactDetails || [];
        this.pages = {};
        for (const page of Object.values(instance?.pages || {})) {
            this.pages[page.pageId] = new Page(page);
        }
    }
    isDifferent(instance){
        if(!instance) {
            console.log("different because instance is null");
            return true;
        }
        const thesePages = Object.values(this.pages);
        const instancePages = Object.values(instance.pages);

        if(thesePages.length !== instancePages.length) {
            console.log("different because Pages are different", thesePages, instancePages);
            return true;
        }
        if(this.urls.length !== instance.urls.length) {
            console.log("different because urls are different");
            return true;
        }

        const differentPages = Object.values(this.pages).filter((page, idx) => {
            return page.isDifferent(instancePages[idx]);
        });

        const differentUrls = this.urls.filter((url, idx) => {
            return url !==  instance.urls[idx];
        })

        console.log("Is instance Different?", differentPages.length > 0 ||
            differentUrls.length > 0 ||
            this._id !== instance._id ||
            this.instanceId !== instance.instanceId ||
            this.title !== instance.title ||
            this.subtitle !== instance.subtitle ||
            this.homeLayout !== instance.homeLayout ||
            this.theme !== instance.theme ||
            this.coverPhoto.isDifferent(instance.coverPhoto) ||
            JSON.stringify(this.contactDetails) !== JSON.stringify(instance.contactDetails))
        return differentPages.length > 0 ||
            differentUrls.length > 0 ||
            this._id !== instance._id ||
            this.instanceId !== instance.instanceId ||
            this.title !== instance.title ||
            this.subtitle !== instance.subtitle ||
            this.homeLayout !== instance.homeLayout ||
            this.theme !== instance.theme ||
            this.coverPhoto.isDifferent(instance.coverPhoto) ||
            JSON.stringify(this.contactDetails) !== JSON.stringify(instance.contactDetails);
    }
}

/**
 * @typedef Page
 */

export class Page {
    /**
     * @param {Page} page
     */
    constructor(page) {
        this.pageId = page?.pageId;
        this.title = page?.title;
        this.coverPhoto = page?.coverPhoto ? new Photo(page.coverPhoto) : null;
        this.enabled = page?.enabled;
        this.blocks = page?.blocks?.map(b => new Block(b)) || [];
    }

    isDifferent(page){
        if(!page) return true;

        // Check if pageId, title, or enabled are different
        if (
            this.pageId !== page.pageId ||
            this.title !== page.title ||
            this.enabled !== page.enabled
        ) {
            return true;
        }

        if(this.coverPhoto?.isDifferent(page.coverPhoto)) {
            return true;
        }

        // Check if blocks array length is different
        if (this.blocks.length !== page.blocks.length) return true;

        // Compare each block
        const differentBlocks = this.blocks.filter((block, idx) => {
            return block.isDifferent(page.blocks[idx]);
        })

        return differentBlocks.length > 0;
    }
}

export const BlockModes = {
    EDIT: "edit",
    READONLY: "readonly"
}

export class Block {
    /**
     * @param {Block} block
     */
    constructor(block) {
        this.type = block?.type;
        this.title = block?.title;
        this.content = block?.content;
        this.mode = block?.mode || BlockModes.READONLY;
    }

    isDifferent(block) {
        // Compare simple properties (type, title, content)
        if (
            this.type !== block.type ||
            this.title !== block.title ||
            this.content !== block.content
        ) {
            return true;
        }

        // No differences found in block
        return false;
    }
}

export const BlockTypes = {
    TEXT: "text",
    TITLE_AND_TEXT: "titleAndText",
    IMAGE: "image",
    MuiDivider: "divider"
}