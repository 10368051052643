import * as React from "react";
import {useEffect, useState} from "react";
import {Button, TextField} from "@mui/material";
import {useAuth} from "../../services/authentication/use-auth";
import {useInstances} from "../../services/instances";
import {Instance} from "../../models/instance";

import "./instance-selector-page.scss";
import InstanceCard from "../../components/instance-card";

export function InstanceSelectorPage() {
    const {
        instances,
        setSelectedInstance,
        createInstance,
        instanceApiError
    } = useInstances();
    const {userData} = useAuth();

    const [newInstanceTitle, setNewInstanceTitle] = useState("");

    useEffect(() => {
        if(setSelectedInstance) {
            setSelectedInstance(null);
        }
    }, [instances, setSelectedInstance]);

    let onNewInstanceTitleChanged = (e) => {
        setNewInstanceTitle(e.target.value);
    }
    const handleCreateInstance = async () => {
        await createInstance(new Instance({
            title: newInstanceTitle
        }));
    }

    return <div id="instance-selector-page">
        {instanceApiError && <div className="error-box">{instanceApiError}</div>}
        <div>
            {userData?.profile?.resource_access?.photos?.roles?.indexOf("admin") >= 0 && <>
                <div id="edit-title" className="input-element">
                    <TextField fullWidth={true} variant="standard"
                               value={newInstanceTitle}
                               placeholder="Instance..."
                               onChange={onNewInstanceTitleChanged}
                    />
                </div>
                <div className="input-buttons">
                    <div className="right-buttons">
                        <Button className="save" variant="contained" color="info"
                                onClick={handleCreateInstance}>
                            Create instance
                        </Button>
                    </div>
                </div>
            </>}
        </div>
        <div id="instance-list">
            {Object.values(instances || {}).map((instance, idx) =>
                <InstanceCard instance={instance} key={idx} />
            )}
        </div>
    </div>;
}